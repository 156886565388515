import React from "react";
export const DoroContext = React.createContext({
  inProgress: "cool",
  setInProgress: () => {},
  leaderBoard: [],
  setLeaderBoard: () => {},
});
export const DoroProvider = DoroContext.Provider;
export default DoroContext;


