import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Navbar, Feed, DoroDetail, CreateDoro, Search } from "../components";

const DoroWrapper = ({ user }) => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="px-2 md:px-5">
      <div className="">
        <Navbar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          user={user && user}
        />
      </div>
      <div className="h-full">
        <Routes>
          <Route path="/" element={<Feed message="Focus Friends..." />} />
          <Route
            path="/doro-detail/:doroId"
            element={<DoroDetail user={user && user} />}
          />
          <Route
            path="/create-doro"
            element={<CreateDoro user={user && user} />}
          />
          <Route
            path="/search"
            element={
              <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default DoroWrapper;
