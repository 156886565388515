import React from 'react';
import Doro from './Doro';

const Doros = ({ doros, reloadFeed }) => (
  <div className="animate-slide-fwd max-w-lg lg:max-w-2xl mx-auto">
    {doros?.map((doro) => (
      <Doro
        key={doro._id}
        doro={doro}
        reloadFeed={reloadFeed}
        className="w-max"
      />
    ))}
  </div>
);

export default Doros;
