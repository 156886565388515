import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &: first-child {
    margin-left: 0;

`;

const Title = styled.span`
  color: #4c5a23;
  font-size: 16px;
  margin-bottom: 5px;
  @media (max-width: 568px) {
    font-size: 12px;
  }
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const SingleDigit = styled.span`
  position: relative;
  display: flex;
  flex: 0 1 25%;
  background-color: ${(props) => props.color};
  transition: background-color 30s ease;
  border-radius: 10px;
  justify-content: center;
  padding: 10px 12px;
  color: white;
  @media (max-width: 568px) {
    font-size: 50px;
    min-width: 60px;
  }
  @media (min-width: 568px) {
    font-size: 100px;
    min-width: 100px;
  }
  &:first-child {
    margin-right: 2px;
  }
  &:after {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    bottom: 50%;
    content: "";
    width: "100%";
    height: 2px;
    background-color: grey;
    box-shadow: 0px 0px 5px 0px grey;
    opacity: 0.4;
  }
`;

export default function Digit({ value, title, color }) {
  const leftDigit = value >= 10 ? value.toString()[0] : "0";
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <Container>
      <Title>{title}</Title>
      <DigitContainer>
        <SingleDigit color={color}>{leftDigit}</SingleDigit>
        <SingleDigit color={color}>{rightDigit}</SingleDigit>
      </DigitContainer>
    </Container>
  );
}
